import env from 'env';

const apiDomain = env.REACT_APP_API_HOST;

/**
 * @deprecated
 * @param path
 * @returns {`${string}/api/v1/`}
 */
export const apiV1Url = (path = '') => `${apiDomain}/api/v1/${path}`;
